import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { LoadingSpinnerProps } from 'cuenect-web-core'
export const LoadingIndicator = styled.div<LoadingSpinnerProps>(
  ({ theme: { fonts, colors }, scale = 1 }) => ({
    display: 'inline-block',
    width: `${40 * scale}px`,
    height: `${40 * scale}px`,
    '&:after': {
      content: '""',
      display: 'block',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      border: '6px solid transparent',
      borderColor: `${colors.primary} transparent ${colors.primary} transparent`,
      animation: `${keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
    `} 1.2s linear infinite`,
    },
  })
)
