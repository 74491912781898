import { Global } from '@emotion/core'
import { Theme, ThemeProvider as CustomThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import {
  AtomsProvider,
  LocalStorageUtil,
  ThemeProvider,
  TranslationProvider,
} from 'cuenect-web-core'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { rgba } from 'emotion-rgba'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { I18nUtil, withTrans } from '../../../i18n'
import { LoadingIndicator } from '../../atoms/loadingIndicator'
import { Footer } from '../footer'
import { Header } from '../header'
import { Button, CookieConsent } from '../..'

import './../../../themes/siemens/global.css'
import { siemensTheme } from './../../../themes/siemens/siemensTheme'
import { mq, LoadAnalytics } from './../../../utility'

dayjs.extend(utc)
dayjs.extend(RelativeTime)

export interface InnerContainerParams {
  isTop?: boolean
}

export interface PageParams extends InnerContainerParams {
  pageContext: {
    lang: 'en' | 'de'
    item?: string
    program?: string
    hidePostEvent?: boolean
  }
  isTop?: boolean
  onScrollerInit: Function
}

export interface PageFrameProps extends PageParams {
  pageName: string
  hideMenu?: boolean
  theme?: Theme
  perectScroll?: boolean
  noFrame?: boolean
  onYScroll?(container: HTMLElement): void
}

export const PageFrame: React.FC<PageFrameProps> = ({
  children,
  pageContext: { lang = 'en', hidePostEvent = false },
  isTop = false,
  pageName,
  hideMenu = false,
  theme = siemensTheme,
  onScrollerInit = null,
  perectScroll = true,
  noFrame,
  onYScroll,
}) => {
  // REDIRECT TRAILING SLASHES
  if (typeof window !== 'undefined') {
    const stripTrailingSlashes = document.location.href.replace(
      /^\/+|\/+$/g,
      ''
    )
    /*  if (document.location.href !== stripTrailingSlashes) {
      navigate(stripTrailingSlashes)

      return <></>
    } */
  }

  const currentheme = theme
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(false)
  }, [loading])

  const content = (
    <>
      {loading && (
        <Loading>
          <LoadingIndicator />
        </Loading>
      )}
      {!noFrame && <Header isTop={isTop} />}
      {!loading && <InnerContainer isTop={isTop}>{children}</InnerContainer>}
      {!noFrame && (
        <>
          <CookieConsent page={pageName} lang={lang} />
          <Footer currentLanguage={lang} />
        </>
      )}
    </>
  )

  return (
    <>
      <Helmet>
        <title>Siemens | Support</title>
        <meta name="description" content="" />
      </Helmet>
      <LoadAnalytics />
      <TranslationProvider
        translations={I18nUtil.flattenLocales(I18nUtil.getLocale(lang))}
      >
        <ThemeProvider theme={theme}>
          <CustomThemeProvider theme={theme}>
            <AtomsProvider
              customAtoms={{
                Button,
              }}
            >
              <>{content} </>
            </AtomsProvider>
          </CustomThemeProvider>
        </ThemeProvider>
      </TranslationProvider>
      <Global
        styles={{
          html: {
            height: '100%',
            overflowY: 'none',
            position: 'inherit',
            overscrollBehavior: 'none',

            backgroundColor: currentheme.colors.bodyBackground,
            fontFamily: currentheme.fonts.fontFamily,
            color: currentheme.colors.text,
            body: {
              borderTop:
                LocalStorageUtil.getString('mockTime') ||
                LocalStorageUtil.getString('mockTimezone')
                  ? `10px solid ${currentheme.colors.error}`
                  : 'none',
            },
            '.customTooltip': {
              background: currentheme.colors.buttonSecondaryBackground,
              maxWidth: '200px',
              zIndex: 999999,
              '&.show': { opacity: 1 },
              '&:after': {
                borderTopColor: `${currentheme.colors.buttonSecondaryBackground} !important`,
              },
            },
            '.showroomTooltip': {
              background: rgba(currentheme.colors.lightBlue, 1),
              maxWidth: '200px',
              borderRadius: 0,
              fontSize: '12px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.lightBlue} !important`,
              },
            },
            '.debugModal > div': {
              background: currentheme.colors.bodyBackground,
              input: {
                color: '#000',
              },
            },
            '.crisp-client': {
              display: pageName === 'support' ? 'block' : 'none',
            },
          },
        }}
      ></Global>
    </>
  )
}
export default withTrans(PageFrame)

const InnerContainer = styled.div<InnerContainerParams>(
  ({ theme: { grid }, isTop }) => `
  padding-top:50px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
  min-height: calc(100vh - 330px);

  ${isTop && 'padding-top:0px; margin-top:0px; min-height:100vh; '}

  ${mq[3]} {
    padding-top:0px;

    min-height: calc(100vh - 370px);
    ${isTop && 'margin-top:-150px; '}
  }


`
)

const Loading = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  height:100vh;
  left:0;top:0;
  backdrop-filter: blur(5px);
  display:flex;
  align-items:center;justify-content:center;
  background:${rgba(colors.bodyBackground, 0.5)};
`
)
const DebugBarTop = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  padding:5px;
  top:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background:${rgba(colors.error, 0.5)};
`
)
