import styled from '@emotion/styled'
import React from 'react'
import { Logo } from '../../atoms/logo'
import { LanguageChange } from '../../molecules/languageChange'
import { languages } from './../../../config/languages'
import { mq } from './../../../utility/breakpoint'
interface HeaderProps {
  isTop?: boolean
}

export const Header: React.FC<HeaderProps> = ({ isTop }) => {
  const [, cLang] = (typeof window !== 'undefined' &&
    (document.location.pathname.match(/feedback\/[a-z]{2}/)
      ? document.location.pathname.match(/feedback\/([a-z]{2})/)
      : document.location.pathname.match(/\/(.{1,2})\/?/))) || ['en', 'en']

  return (
    <HeaderContainer className={isTop ? 'noBackground' : ''}>
      <Logo uri={'http://www.siemens.com'} />
      <LanguageChangeContainer>
        {languages.languages.length > 1 && (
          <LanguageChange currentLanguage={cLang} />
        )}
      </LanguageChangeContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header(({ theme: { grid, colors } }) => ({
  pointerEvents: 'none',
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columns},1fr)`,
  position: 'absolute',
  top: 0,
  paddingTop:
    '40px' /*
  Disable transparent header for mobile
  '&.noBackground':{
    background: 'transparent',
  }, */,
  /*   background: colors.bodyBackground,
   */ zIndex: 100,
  [mq[3]]: {
    paddingBottom: '20px',

    background: 'transparent',
    position: 'relative',
    top: 'inherit',
  },
  '& > *': {
    pointerEvents: 'all',
  },
}))

const LanguageChangeContainer = styled.div(() => ({
  gridColumn: '20/35',
  display: 'flex',
  justifyContent: 'flex-end',
}))
