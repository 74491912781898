import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { SocialList } from './../../'
import { languages } from './../../../config/languages'
import { mq } from './../../../utility/breakpoint'

import { useTranslation } from 'react-i18next'
import { FooterMenu } from '../../molecules/footerMenu'
import { LanguageChange } from '../../molecules/languageChange'

interface FooterProps {
  currentLanguage: string
}
export const Footer: React.FC<FooterProps> = ({ currentLanguage }) => {
  const { t } = useTranslation(`footer`)

  return (
    <FooterContainer>
      {languages.languages.length > 1 && (
        <LanguageChangeContainer>
          <LanguageChange currentLanguage={currentLanguage} />
        </LanguageChangeContainer>
      )}
      <FooterMenuContainer>
        <FooterMenu language={currentLanguage} />
      </FooterMenuContainer>
      <Social>
        <SocialList />
      </Social>
      <Website>
        <a href={t('global.url')} target="_blank">
          {t('global.label')}
        </a>
        <Copyright>{t('copyright')}</Copyright>
      </Website>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer(
  ({ theme: { grid, colors } }) => `
  background-color:${colors.footer};
  display:grid;
  grid-template-columns:repeat(${grid.columns},1fr);
  grid-area:footer;
  color:white;
  padding-bottom:40px;
  margin-top:20px;
`
)

const LanguageChangeContainer = styled.div`
  margin: 0;
  padding: 0;
  ${mq[1]} {
    margin-top: 32px;
    margin-bottom: 40px;
  }
  ${mq[3]} {
    margin-top: 56px;
    margin-bottom: 58px;
  }
  grid-column-end: 10;
  grid-row: 1;
  grid-column-start: 3;
`

const FooterMenuContainer = styled.div`
  margin-top: 56px;
  margin-bottom: 58px;
  grid-column-end: 28;
  grid-row: 3;
  grid-column-start: 3;
  ${mq[1]} {
    grid-row: 2;
  }
  ${mq[3]} {
    grid-row: 1;
    grid-column-start: 11;
  }
  display: flex;
  justify-content: center;
`

const Social = styled.div`
  grid-column-end: 35;
  grid-row: 2;
  grid-column-start: 3;
  display: flex;
  ${mq[1]} {
    grid-row: 1;
    grid-column-start: 27;
    margin-top: 20px;
    justify-content: flex-end;
  }
  ${mq[3]} {
    grid-column-start: 30;
  }
`

const Website = styled.div(
  ({ theme: { colors } }) => `
  grid-column-end: 35;
  grid-row-start: 4;
  text-align:center;
  grid-column-start: 3;
  font-size:.875rem;
  a{
    color:${colors.link};
    text-decoration:underline;
    white-space:nowrap;
    &:hover{
      color:${colors.linkHover};
    }
  }
  ${mq[1]} {
    grid-row-start: 3;
    text-align:left;
    a{
      padding-right:20px;

    }
  }
`
)

const Copyright = styled.div`
  margin-top: 10px;
  ${mq[1]} {
    float: right;
  }
`
